.blog__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog__header2 {
    cursor: pointer;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-box {
    cursor: pointer;
    border: 1px solid #023455;
    border-radius: 5px;
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.img-blog-view{
    padding: 0.25rem!important;
    background-color: #ffffff!important;
    border: 1px solid #E4E6EF!important;
    border-radius: 0.42rem;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
    max-width: 100%;
    height: -webkit-fill-available;

}