.table th {
    color: white;
    text-align: "start";
}

.box {
    height: 15px;
    width: 15px;
    border-radius: 10px;
    background-color: red;
}
.box2 {
    height: 15px;
    width: 15px;
    border-radius: 10px;
    background-color: green;
}
/* .header{
    display: flex;
    justify-content: space-between;
} */
